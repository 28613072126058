$mobile-breakpoint: 480px;
$small-breakpoint: 900px;
$medium-breakpoint: 1200px;

html body {
  font-family: 'Archivo';
}

.spinner {
  display: flex;
  justify-content: center;
  margin: auto;
  height: 100%;
  width: 100%;
}

#clientinfo-header {
  display: flex;

  #clientinfo-link {
    align-items: center;
    justify-content: left;
    display: flex;
    flex: 1;
    gap: 10px;
  }

  #submit-clientinfo {
    display: flex;
    align-items: center;
    margin: 10px;
  }
}

#clientlist-header {
  display: flex;
  .header-left {
    align-items: center;
    justify-content: left;
    display: flex;
    flex: 1;
    gap: 20px;
  }

  .header-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}

.landing-header {
  display: flex;
  justify-content: flex-end;
}

.goop-totem-links-container, .codes-rev-container, .data-recency-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 1.5em 0 1.5em 30px;
  flex-wrap: wrap;
}


.goop-totem-links-container {
  img {
    width: 200px;
    margin: auto;
    display: block;
  }
}

.section-header-text {
  margin-left: 2em;
}

.codes-rev-container, .data-recency-container, .goop-totem-links-container  {

  .MuiCard-root {
    width: 310px;
    margin: 10px;
  }


  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
}

.goop-links, .totem-links {
  flex-direction: column;
  width: 300px;
  h4 {
    text-align: center;
  }
}

.data-recency-container {
  padding: 15px 0 15px 0;
}

.codes-rev-container.div {
  width: 250px;
}

.section header {
  margin-left: 300px;
}

.class-count {
  font-size: 22px;
  font-weight: 800;
  text-align: center;
}

.last-updated-timestamp {
  color:rgb(96, 94, 94);
  font-size: 14px;
}

.data-recency-last-updated-timestamp {
  color:rgb(96, 94, 94);
}

.codes-subtext, .subtext {
  text-align: center;
  margin: 0;
}

.subtext {
  margin-bottom: 20px;
}

.subtext {
  font-weight: 800;
}

.data-recency-card, .codes-card {
  vertical-align: middle;
  p {
    text-align: center;
  }
}


.MuiCardHeader-title {
  font-weight: 800 !important;
  font-size: 16px !important;
}

.codes-widget:hover {
  cursor: pointer;
}

.timestamp {
  display: flex;
  width: 100%;
  p {
    color: rgb(102, 101, 101);
    font-size: 12px;
  }
}

.MuiToolbar-root {
  height: 64px;
}

.appbar-avatar-container  {
  position: absolute !important;
  right: 15px;
}

.missionportal-logo-container {
  margin: 10% 0 10% 0;
  width: 100%;
  display: flex;

  .missionportal-logo {
    justify-content: center;
    margin: auto;
  }

  img {
    height: auto;
    transition: width .3s ease-in-out;
  }
}

#transactions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-tab {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .checkbox-field {
    margin-right: 5px;
  }
}

.client-card {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    margin: 0px;
  }
}

#data-ingest, #adai-columns {
  display: flex;
  gap: 40px;
  flex-direction: column;

  h2 {
    margin: 0px;
  }

  @media (min-width: $mobile-breakpoint) {
    flex-direction: row;
  }

  #ingest-basics, #adai-info {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 25px;

    #ingest-checks, #delivery-cards {
      display: flex;
      flex-direction: column;
    }
  }

  #ingest-basics {
    flex-basis: 0;
  }

  #delivery-cards {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  #ingest-data-source, #adai-delivery-selections {
    flex-grow: 1;
    min-width: 0;
  }

  #ingest-data-source {
    flex-basis: 0;
  }
}

.MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 800 !important;
}

.MuiDataGrid-columnHeader {
  background-color: #e8f0f8;
  border-bottom: rgb(103, 102, 102) 1px solid !important;
  border-right: 1px solid rgb(219, 216, 216) !important;
}

.MuiDataGrid-cell {
  border-right: 1px solid rgb(219, 216, 216) !important;
}

.header_table_button_container {
  display: flex;
  width: 100%;
  padding: 5px;
}

.exclusion-buttons {
  margin: 5px;
}

.fetch-data-btn-container {
  display: flex;
  justify-content: flex-end;
}

button.toggle-search-type {
  margin-left: 15px;
}

.button_container-transactions_1, .button_container-transactions_2 {
  width: 100%;
}
.button_container-transactions_2 {
  display: flex;
  justify-content: flex-start;
}

.chip-list {
  display: flex;
  flex-wrap: wrap;
}

#advanced-search {
  .search-select {
    display: flex;
    align-items: center;
    margin: 10px;
  }
}

#advanced-search-dialog-body {
  margin: 0 10px 10px 10px;

  .submit-button {
    margin: 10px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1000px) {
      justify-content: center;
    }
  }
}

#advanced-search-options {
  display: flex;

  #sort-selector {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .search-half {
    max-width: 750px;
    padding: 0 30px 0 30px;

    .or-button-container {
      display: flex;
      justify-content: center;
    }
  }

  .search-criteria {
    display: flex;
    justify-content: flex-end;
    gap: 2px;
    margin: 10px 0 10px 0;
  }

  @media (max-width: 1300px) {
    flex-direction: column-reverse;

    .sort-container {
      width: 100%;

      #sort-column {
        width: 350px;
      }

      #sort-direction {
        width: 175px;
      }
    }

    h3 {
      font-size: 1rem;
    }
  }
}

.chip-list {
  margin: 10px;
  display: flex;
  justify-content: flex-end;

  .search-chip {
    margin: 0 5px 0 5px;
  }
}

.right-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.sumBySourceWidget-container {
  width: 100%;
  display: flex;

  .sumBySourceWidget-column {
    padding: 5%;
    flex-direction: row;
    justify-content: center;

    .sumBySourceWidget-questions {
      display: flex;
      flex-direction: column;
    }
  }
}

.sumBySource-button-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  button {
    width: 20%;
  }
}

.table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dromo-uploader {
  text-transform: uppercase;
  background-color: #00a69c;
  border: 1px solid #00a69c;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    cursor: pointer;
    background-color: #00746d;
    border-color: #00746d
  }
}

.import-options {
  display: flex;
  align-items: center;
}

@media (max-width: $small-breakpoint) {
  .sumBySourceWidget-container {
    flex-direction: column;
  }
}

.codes-source-search-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  img {
    max-height: 56px;
    padding-left: 5px;
  }
}

// Reporting
.card-list {
  padding-left: 20px;
  margin-bottom: 0px;
}

.reporting-row-container {
  display: flex;
  gap: 20px;
  margin-left: 50px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.view-report-button {
  justify-content: center !important;
}

// shrink the Mui tabs if they are longer than the screen
.MuiTab-root {
  flex-shrink: 1 !important;
}
